import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <div className='footer'>
        <div className="flists">
            <ul className="flist">
                <li className="flistitem">Countries</li>
                <li className="flistitem">Regions</li>
                <li className="flistitem">Cities</li>
                <li className="flistitem">Districts</li>
                <li className="flistitem">Airports</li>
                <li className="flistitem">Hotels</li>
            </ul>

            <ul className="flist">
                <li className="flistitem">Countries</li>
                <li className="flistitem">Regions</li>
                <li className="flistitem">Cities</li>
                <li className="flistitem">Districts</li>
                <li className="flistitem">Airports</li>
                <li className="flistitem">Hotels</li>
            </ul>

            <ul className="flist">
                <li className="flistitem">Countries</li>
                <li className="flistitem">Regions</li>
                <li className="flistitem">Cities</li>
                <li className="flistitem">Districts</li>
                <li className="flistitem">Airports</li>
                <li className="flistitem">Hotels</li>
            </ul>

            <ul className="flist">
                <li className="flistitem">Countries</li>
                <li className="flistitem">Regions</li>
                <li className="flistitem">Cities</li>
                <li className="flistitem">Districts</li>
                <li className="flistitem">Airports</li>
                <li className="flistitem">Hotels</li>
            </ul>

            <ul className="flist">
                <li className="flistitem">Countries</li>
                <li className="flistitem">Regions</li>
                <li className="flistitem">Cities</li>
                <li className="flistitem">Districts</li>
                <li className="flistitem">Airports</li>
                <li className="flistitem">Hotels</li>
            </ul>
        </div>
        <div className="ftext">Copyright &#169; 2023 WanderLust Retreat</div>
    </div>
  )
}
